import { useEffect, useState } from "react";

const useSwipeTrackDeviceName = () => {
  const [deviceName, setDeviceName] = useState("");

  useEffect(() => {
    window.stBrowserDidReportDeviceName = (name) => {
      setDeviceName(name);
    };

    $("<iframe />").attr("src", "stBrowser://getDeviceName").attr("style", "display: none;").appendTo("body");

    return () => {
      window.stBrowserDidReportDeviceName = undefined;
    };
  }, []);

  return deviceName;
};

export default useSwipeTrackDeviceName;
