import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import useTranslation from "hooks/useTranslation";
import requestToServer from "utils/requestToServer";
import useSwipeTrackDeviceName from "hooks/useSwipeTrackDeviceName";

const Activation = ({ path }) => {
  const deviceName = useSwipeTrackDeviceName();
  const [isSubmitting, setSubmitting] = useState(false);

  const t = useTranslation("check_in.activation");

  const handleSubmit = () => {
    setSubmitting(true);
    requestToServer({
      path,
      method: "POST",
      body: { device_name: deviceName },
    });
  };

  return (
    <>
      <Box mb={2} textAlign="center">
        <Typography>{t("description")}</Typography>
      </Box>
      <Button disabled={isSubmitting} loading={isSubmitting} onClick={handleSubmit}>
        {t("activate")}
      </Button>
    </>
  );
};

export default Activation;
