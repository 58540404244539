import "translations/check-in";
import "utils/initYupValidation";
import "../stylesheets/universal.scss";
import decorateComponents from "utils/decorateComponents";
import renderComponents from "utils/render_components";
import renderReactElements from "utils/renderReactElements";
import { withErrorBoundary } from "utils/withErrorBoundary";
import * as checkInComponents from "check_in/check_in_components";
import { withMuiTheme } from "theme/utils";
import setCssVariables from "theme/setCssVariables";
import { withStepConfig } from "hooks/useStepConfig";
import muiTheme from "theme/muiTheme";

setCssVariables(muiTheme);

document.addEventListener("DOMContentLoaded", () => {
  const decoratedComponents = decorateComponents(checkInComponents, [
    withErrorBoundary,
    withMuiTheme(muiTheme),
    withStepConfig,
  ]);
  renderReactElements(decoratedComponents);
  renderComponents(decoratedComponents);
});
